import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import axios from '../../CustomComponents/Functions/AxiosInstance';
export const LoginForm = (props) => {
    const buttonStyle = {
        backgroundColor: '#008CBA',
        marginTop: '10px'
      };
      const SAMLbuttonStyle = {
        backgroundImage: 'url("https://upload.wikimedia.org/wikipedia/commons/4/49/Office_365_logo_%282013-2019%29.png")',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        marginTop: '10px',
        backgroundColor: '#008CBA',
        height: '35px'
    };
    function LoginHandler(event) {
        event.preventDefault();
        const url = '/api/login';
        const data = {
          Username: event.target.username.value,
          Password: event.target.password.value
        };
        axios.post(url, data)
          .then(response => {
            window.location.href = '/';
          })
          .catch(error => {
            console.error('Wrong login:', error.response ? error.response.data : 'Unknown error');
            alert('Wrong login.');
          });
    }
    function SAML() {
        if (process.env.NODE_ENV === 'development') {
            window.location.href = 'http://localhost:8080/loginSAML';
        } else {
            window.location.href = '/loginSAML';
        }
    }
    return (
        <div className="App">
            <div class="d-flex align-items-center justify-content-center vh-100">
            <div class="login shadow p-4 col-4">
                <div class="text-center"><h1>Login</h1></div>
                <form id="loginForm" onSubmit={LoginHandler}>
                    <input className="form-control" type="text" name="username" placeholder="Gebruikersnaam"/>
                    <br></br>
                    <input className="form-control" type="password" name="password" placeholder="Wachtwoord"/>
                    <br></br>
                    <button className="form-control" type="submit" style={buttonStyle}>Login</button>
                    <button className="form-control" type="button" style={SAMLbuttonStyle} onClick={SAML}></button>
                </form>
            </div>
        </div>
        </div>
    );
};