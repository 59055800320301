import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: '/',
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json'
    }
});

axiosInstance.interceptors.response.use(
    response => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response) {
            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                try {
                    const rs = await axios.post('/api/refresh', {}, {withCredentials: true});
                    const {accessToken} = rs.data;
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
                    return axios(originalRequest);
                } catch (refreshError) {
                    console.log('Unable to refresh token:', refreshError);
                    window.location.href = '/login';
                }
            } else if (error.response.status === 404) {
                window.location.href = '/';
            }
        }else {
            console.error("An error occurred:", error.message);
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;