import { NavLink } from 'react-router-dom';
const NavItem = ({ rights, link, text }) => {
    const activeStyle = {
        color: '#00Ad5F',  // You can customize the color or any other styles for active links
        textDecoration: 'none'
    };
    const inactiveStyle = {
        color: '#999999',  // You can customize the color or any other styles for active links
        textDecoration: 'none'
    };
    return (
    <div>
        {rights === "1" && (<NavLink to={link} className={({ isActive }) => "nav-item nav-link" + (isActive ? " active" : "")} style={({ isActive }) => isActive ? activeStyle : inactiveStyle}>
            {text}
        </NavLink>)}
    </div>
    );
  };
  
  export {NavItem};