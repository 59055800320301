import React, { useRef, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Button } from 'react-bootstrap';
import axios from '../../CustomComponents/Functions/AxiosInstance';
import { ClientTable, DeletePopup, AttributesPopup } from './Components';
export const ClientsForm = (props) => {
    const [MydefaultValues, setdefaultValues] = useState([]);
    const [Clients, setClients] = useState([]);
    const [DeleteName, setDeleteName] = useState([]);
    const [DeleteData, setDeleteData] = useState([]);
    const devicePopupRef = useRef();
    const deletePopupRef = useRef();
    const fetchClients = async () => {
        const response = await axios.get('/api/Get/Client');
        setClients(response.data)
    };
    const PopupDevice = () => {
        devicePopupRef.current.Popup();
    };
    const PopupAttributes = {
        Title: "Adjust Clients",
        Fields: [
            {
                Type: "TextField",
                Label: "Naam",
                Name: "Name"
            }
        ],
        AddUID: true
    }
    useEffect(() => {
        fetchClients();
    }, []);
    const PopupDelete = (Id) => {
        const device = Clients.find(client => client.ID === Id);
        setDeleteName("Ben je zeker dat je "+device.Client+" wilt verwijderen ?");
        setDeleteData({ID: Id});
        deletePopupRef.current.Popup();
    }
    return (
        <div className="container">
            <div className="row align-items-center justify-content-center">
                <div className="col-10 pt-5">
                    <div className="row">
                        <div className="col-12 text-center d-flex p-3">
                            <Button variant="primary" className="w-100" onClick={() => {setdefaultValues({});PopupDevice();}}>Klant Toevoegen</Button>
                        </div>
                    </div>
                    <ClientTable
                        Clients={Clients}
                        PopupDelete={PopupDelete}
                        PopupDevice={PopupDevice}
                        setdefaultValues={setdefaultValues}
                    />
                </div>
            </div>
            <DeletePopup
                Message={DeleteName}
                Url='/api/delete/Client'
                Data={DeleteData}
                ref={deletePopupRef}
            />
            <AttributesPopup DefaultValues={MydefaultValues} Route="Client" ref={devicePopupRef} Attributes={PopupAttributes}/>
        </div>
    )
};