import { Button } from 'react-bootstrap';
const InstallationTable = ({Installations, Clients, PopupDelete, PopupDevice, setdefaultValues}) => {
    function EditDevice(Unique_ID) {
        const CurDevice = Installations.filter(device => device.ID === Unique_ID);
        setdefaultValues(CurDevice[0])
        PopupDevice()
    }
  return (
    <table className="table">
        <tr>
            <th>INSTALLATIE</th>
            <th>KLANT</th>
            <th>ID</th>
            <th>AANPASSEN</th>
            <th>VERWIJDER</th>
        </tr>
        {Installations.map((Installation, index) => {
            const CurClient = Clients.filter(client => client.ID === Installation.Client_ID);
            let ClientName = ""
            if(CurClient[0]){ClientName = CurClient[0].Name}
            return (
                <tr>
                    <td>{Installation.Name}</td>
                    <td>{ClientName}</td>
                    <td>{Installation.Unique_ID}</td>
                    <td><Button variant="warning" onClick={() => EditDevice(Installation.ID)}>Aanpassen</Button></td>
                    <td><Button variant="danger" onClick={() => PopupDelete(Installation.ID)}>Verwijder</Button></td>
                </tr>
            );
        })}
    </table>
  );
};
export {InstallationTable};