import * as React from "react";
import { CompactTable } from "@table-library/react-table-library/compact";
import { useTheme } from '@table-library/react-table-library/theme';
import {useSort} from "@table-library/react-table-library/sort";
const CustomTable = ({Columns, Values}) => {
    const data = { nodes: Values };
    const customTheme = {
        HeaderRow: `
        .th{
           background-color: #393939;
        color: #00Ad5F;
        fill: #00Ad5F;
        border-bottom: 0px;
        border-right: 2px solid #00Ad5F;
        position: sticky;
        top: 0;
        z-index: 2;
        }
        `,
        Row: `
        &:nth-of-type(odd) {
            .td{
                background-color: #222222;
                color: white;
                border-bottom: 0px;
            }
        }
        &:nth-of-type(even) {
            .td{
                background-color: #333333;
                color: white;
                border-bottom: 0px;
            }
        }
    `,
    };
    const theme = useTheme([customTheme]);
    const sortFns = Columns.reduce((acc, column) => {
        acc[column.label] = (array) => array.sort((a, b) => {
            const key = column.label;
            if (column.SortType === "Date") {
                return a.Timestamp - b.Timestamp;
            }
            return a[key].localeCompare(b[key]);
        });
        return acc;
    }, {});
    const Sort = useSort(
        Values,
        {},
        {
            sortFns
        }
    );
    Columns = Columns.map(column => ({
        ...column,
        ...(column.SortType ? { sort: { sortKey: column.label } } : {}),
        renderCell: column.renderCell || (column.SortType === "Date"
            ? (item) => {
                const date = item.Timestamp.toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric"
                });
                const time = item.Timestamp.toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: false,
                });
                return `${date} - ${time}`;
            }
            : (item) => item[column.label]),
        resize: true
    }));
    return (
        <div>
            <CompactTable columns={Columns} data={data} theme={theme} sort={Sort} layout={{ fixedHeader: true }}/>
            <br />
        </div>
    );
};

export { CustomTable };