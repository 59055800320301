import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import axios from '../../CustomComponents/Functions/AxiosInstance';
import {GetBinary} from '../../CustomComponents'
import { CustomTable} from '../Admin';
import {useParams} from "react-router-dom";
export const LogForm = () => {
    const [rights, setRights] = useState([]);
    const [Values, setValues] = useState([]);
    const { clientId, installationId, DeviceID } = useParams();
    const fetchRights = async () => {
        const response2 = await axios.get('/api/GetRights');
        setRights(GetBinary(response2.data));
    };
    useEffect(() => {
        fetchLogs()
        fetchRights()
        // eslint-disable-next-line
    }, [DeviceID]);
    const fetchLogs = async () => {
        const NewValues = []
        axios.post('/api/GetLogs', {Device: DeviceID})
            .then(response => {
                if(response.data) {
                    const logs = response.data
                    for (const log of logs) {
                        NewValues.push({
                            Severity: log.Severity,
                            Message: log.Message,
                            Timestamp: new Date(log.Date),
                            Category: log.Category,
                            Source: log.Source
                        })
                        setValues(NewValues)
                    }
                }
            })
            .catch(error => {
                console.log(error)
            });
    }
    if(rights[1]==="0") {
        window.location.href = '/';
    }
    const COLUMNS = [
        {
            label: "Severity",
            SortType: "text"
        },
        {
            label: "Date",
            SortType: "Date"
        },
        {
            label: "Message",
            SortType: "text"
        },
        {
            label: "Category",
            SortType: "text"
        },
        {
            label: "Source",
            SortType: "text"
        },
    ];
    return (
        <div className="container">
            <div className="row align-items-center justify-content-center">
                <div className="col-10 pt-5">
                    <div className="text-center">
                        <a href={`../../../`} className="link">
                            <h3 className="White link">Home/</h3>
                        </a>
                        <a href={`../../${clientId}`} className="link">
                            <h3 className="White link">{clientId}</h3>
                        </a>
                        <a href={`../${installationId}`} className="link">
                            <h3 className="White link">/{installationId}</h3>
                        </a>
                        <h3 className="White link">/{DeviceID}</h3>
                    </div>
                    <div style={{maxHeight: '70vh', overflow: 'auto'}}>
                        <CustomTable Columns={COLUMNS} Values={Values}/>
                    </div>
                </div>
            </div>
        </div>
    );
};