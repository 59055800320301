import * as Sentry from "@sentry/react";
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {Client, Installation, UserPage, LoginForm, AdminMenu, DevicesForm, InstallationsForm, ClientsForm, AccountsForm, PluginForm, LogForm, Tailscale} from './Pages'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

Sentry.init({
  dsn: "https://af9be19021bdb38c438dbc606f07da5a@o4507232213794816.ingest.de.sentry.io/4507768603279440",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^\//],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Routes>
      <Route path="User" element={<UserPage />} />
      <Route path="Admin" element={<AdminMenu />}>
        <Route path="Devices" element={<DevicesForm />} />
        <Route path="Installations" element={<InstallationsForm />} />
        <Route path="Clients" element={<ClientsForm />} />
        <Route path="Accounts" element={<AccountsForm />} />
        <Route path="Plugins" element={<PluginForm />} />
      </Route>
      <Route path="Login" element={<LoginForm />} />
      <Route path="Tailscale" element={<Tailscale />} />
      <Route path=":clientId" element={<Client />} />
      <Route path=":clientId/:installationId" element={<Installation />} />
      <Route path=":clientId/:installationId/:DeviceID" element={<LogForm />} />
      <Route path="*" element={<UserPage />} />
    </Routes>
  </Router>
);

reportWebVitals();