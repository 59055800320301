import React, { forwardRef, useState, useImperativeHandle } from 'react';
import { Button } from 'react-bootstrap';
import axios from '../../../CustomComponents/Functions/AxiosInstance';
import Modal from 'react-bootstrap/Modal';
const DeletePopup = forwardRef(({Message, Url, Data}, ref) => {
    const [showDelete, setShowDelete] = useState(false);
    const [ConfText, setConfText] = useState("");
    function Popup() {
        setShowDelete(true)
    }
    useImperativeHandle(ref, () => ({
        Popup
    }));
    const handleClose = () => {setShowDelete(false);setConfText("")}
    const handleDelete = () => {
        if(ConfText === "verwijder") {
            handleClose()
            axios.post(Url, Data)
              .then(response => {
              })
              .catch(error => {
                console.error('Error updating device:', error.response ? error.response.data : 'Unknown error');
                alert('Failed to delete.');
              });
        }
    };
    return (
        <Modal show={showDelete} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Ben je zeker ?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {Message}
                <p></p>
                <hr/>
                Typ "verwijder" ter bevestiging
                <input className="form-control" type="text" name="delete" value={ConfText} autoComplete={"off"} onChange={(e) => setConfText(e.target.value)}/>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Nee
            </Button>
            <Button variant="danger" onClick={handleDelete}>
                Ja
            </Button>
            </Modal.Footer>
        </Modal>
    );
});
export {DeletePopup};