import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import axios from '../../../CustomComponents/Functions/AxiosInstance';
import {TextField, Selection, FileUpload} from '../../../CustomComponents'
const { v4: uuidv4 } = require('uuid');
const AttributesPopup = forwardRef(({DefaultValues, Route, Attributes, Values, ValueID, TextID}, ref) => {
    const [showAdd, setShowAdd] = useState(false);
    const handleCloseAdd = () => setShowAdd(false);
    const [FormValues, setFormValues] = useState({
        Name: ''
    });
    const componentMapping = {
        TextField,
        Selection,
        FileUpload,
        Button
      };
    function Popup() {
        setShowAdd(true)
    }
    useImperativeHandle(ref, () => ({
        Popup
    }));
    useEffect(() => {
        if (DefaultValues.ID) {
            setFormValues(DefaultValues)
            setFormValues({
                ...FormValues,
                Name: DefaultValues.Client
            });
        }
        else {
            setFormValues({
                ...FormValues,
                Name: "",
            });
        }
        // eslint-disable-next-line
    }, []);
    const handleAdd = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = {};
        formData.forEach((value, key) => {
            data[key] = value;
        });
        let url = ""
        if(DefaultValues.ID ) {
            url = '/api/change/'+Route
        }
        else {
            url = '/api/add/'+Route
            if(Attributes.AddUID) {
                data.Unique_ID = uuidv4();
            }
        }
        axios.post(url, data)
          .then(response => {
            setShowAdd(false);
          })
          .catch(error => {
            console.error('Error adding installation:', error.response ? error.response.data : 'Unknown error');
            alert('Failed to add client.');
          });
    }
    return (
        <Modal show={showAdd} onHide={handleCloseAdd}>
            <Modal.Header closeButton>
                <Modal.Title>{Attributes.Title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="loginForm" onSubmit={handleAdd}>
                {
                    Attributes.Fields.map((field) => {
                        const Component = componentMapping[field.Type];
                        return Component ? (
                            <Component Label={field.Label} Name={field.Name} Value={FormValues} OnChange={setFormValues} Values={Values} ValueID={ValueID} TextID={TextID}/>
                        ) : null;
                    })
                }
                <input type="hidden" name="ID" value={DefaultValues.ID}/>
                <Button variant="secondary" onClick={handleCloseAdd}>
                    Annuleer
                </Button>
                <Button variant="danger" type="submit">
                    Ok
                </Button>
                </form>
            </Modal.Body>
        </Modal>
    );
});
export {AttributesPopup};