import {Card, Col} from 'react-bootstrap';  
const DeviceCard = ({ Name, LastConnection, Online, Status, Link }) => {
    console.log(Online)
    const getBackgroundColor = (status) => {
        if (status === 0) {
            return '#ff2500';
        } else if (status === 1) {
            return '#28a745';
        } else if (status === 2) {
            return '#ffc107';
        } else {
            return '#ffffff';
        }
    };
    const cardStyle = {
        backgroundColor: getBackgroundColor(Online),
        color: '#fff',
        border: 0,
        cursor: "pointer"
    };
    LastConnection = new Date(LastConnection)
    function Continue() {
        if(Link !== undefined) {
            window.location.href = Link;
        }
    }
  return (
        <Col md="4" className="my-3">  
            <Card style={cardStyle} className='shadow' onClick={() => Continue()}>
                <Card.Body>  
                    <Card.Title>{Name}</Card.Title>  
                    <Card.Text>
                        Status: <p>{Status}</p>
                        Last connection: <p>{LastConnection.toLocaleDateString('en-GB', {day: '2-digit',month: '2-digit',year: 'numeric'})}<br></br>{LastConnection.toLocaleTimeString('en-US', {hour: '2-digit',minute: '2-digit',hour12: false})}</p> <br></br>
                    </Card.Text>
                </Card.Body>  
            </Card>  
        </Col>
  );
};

export {DeviceCard};