import React, { useRef, useState, useEffect, useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Button } from 'react-bootstrap';
import axios from '../../CustomComponents/Functions/AxiosInstance';
import {GetBinary, Selection} from '../../CustomComponents'
import {DevicePopup, DeviceTable, DeletePopup} from './Components'
export const DevicesForm = (props) => {
    const [MydefaultValues, setdefaultValues] = useState([]);
    const [devices, setDevices] = useState([]);
    const [rights, setRights] = useState([]);
    const [Clients, setClients] = useState([]);
    const [Installations, setInstallation] = useState([]);
    const [InstallationList, setInstallationList] = useState([]);
    const [InstallationSelected, setInstallationSelected] = useState("");
    const [ClientSelected, setClientSelected] = useState("");
    const [toggleStates, setToggleStates] = useState({});
    const [deletedeviceName, setDeleteDeviceName] = useState([]);
    const [Deletedata, setDeleteData] = useState([]);
    const [deviceTypes , setDeviceTypes] = useState([]);
    const devicePopupRef = useRef();
    const deletePopupRef = useRef();
    const fetchDevices = useCallback(async () => {
        const response2 = await axios.get('/api/GetRights');
        setRights(GetBinary(response2.data));
        try {
            let params = ""
            if(InstallationSelected!=="") {
                params = '"Installation": '+InstallationSelected
            }
            else if(ClientSelected!=="") {
                params = '"Client": "'+ClientSelected+'"'
            }
            axios.post('/api/GetDevices', '{'+params+'}')
                .then(response => {
                    setDevices(response.data);
                    response.data.map((device, index) => {
                        const Checked = device.Enabled === 1 ? true : false
                        setToggleStates(prev => ({ ...prev, [device.Unique_ID]: Checked }));
                        return device;
                    })
                })
                .catch(error => {
                    console.error('Error getting device:', error.response ? error.response.data : 'Unknown error');
                });
        } catch (error) {
            console.error("Could not fetch devices", error);
        }
    }, [ClientSelected, InstallationSelected]);
    useEffect(() => {
        fetchDevices();
        fetchClients();
        fetchInstallations();
        fetchDeviceTypes();
        const interval = setInterval(() => {
            fetchDevices();
        }, 5000);
        return () => clearInterval(interval); 
    }, [ClientSelected, InstallationSelected, fetchDevices]);
    const PopupDelete = (Id) => {
        const device = devices.find(device => device.Unique_ID === Id);
        setDeleteDeviceName("Ben je zeker dat je "+device.Name+" wilt verwijderen ?");
        setDeleteData({ID: device.ID});
        deletePopupRef.current.Popup();
    }
    function updateDevice(device, newStatus) {
        const url = '/api/change/Devices';
        device.Enabled = newStatus
        let updateDevice = {
            ID: device.ID,
            Unique_ID: device.Unique_ID,
            Name: device.Name,
            IP_Address: device.IP_Address,
            MAC: device.MAC,
            Manufacturer: device.Manufacturer,
            Model: device.Model,
            Firmware: device.Firmware,
            Type: device.Type,
            API_user: device.API_user,
            API_pass: device.API_pass,
            Enabled: device.Enabled,
        }
        axios.post(url, updateDevice)
          .then(response => {
          })
          .catch(error => {
            console.error('Error updating device:', error.response ? error.response.data : 'Unknown error');
            alert('Failed to update device.');
          });
      }
    const fetchClients = async () => {
        const response = await axios.get('/api/Get/Client');
        setClients(response.data)
    };
    const fetchDeviceTypes = async () => {
        const response = await axios.get('/api/getDeviceTypes');
        setDeviceTypes(response.data);
    }
    const fetchInstallations = async () => {
        axios.post('/api/GetInstallations', '{}')
        .then(response => {
            setInstallation(response.data);
        })
        .catch(error => {
            console.error('Error getting installation:', error.response ? error.response.data : 'Unknown error');
        });
    };
    const handleToggle = (deviceId, checked) => {
        setToggleStates(prev => ({ ...prev, [deviceId]: checked }));
        let Checked = checked === true ? "1" : "0"
        updateDevice(deviceId, Checked);
    };
    const UpdateClient = (event) => {
        let MyList = []
        Installations.map((installation, index) => {
            if(installation.Client_ID.toString() === event.target.value) {
                MyList.push(installation)
            }
            return installation;
        })
        setClientSelected(event.target.value)
        setInstallationSelected("")
        setInstallationList(MyList)
    }
    const UpdateInstallation = (event) => {
        setInstallationSelected(event.target.value)
    }
    const PopupDevice = () => {
        devicePopupRef.current.Popup();
    };
    if(rights[1]==="0") {
        window.location.href = '/';
    }
    return (
        <div className="container">
            <div className="row align-items-center justify-content-center">
                <div className="col-10 pt-5">
                    <div className="row">
                        <div className="col-5 text-center">
                            <Selection
                                Label="Klant"
                                OnChange={UpdateClient}
                                Values={Clients}
                                ValueID="ID"
                                TextID="Name"
                                CurVal={ClientSelected}
                                SpecialClass="White Large"
                            />
                        </div>
                        <div className="col-5 text-center">
                            <Selection
                                Label="Installatie"
                                OnChange={UpdateInstallation}
                                Values={InstallationList}
                                ValueID="ID"
                                TextID="Name"
                                CurVal={InstallationSelected}
                                SpecialClass="White Large"
                            />
                        </div>
                        {rights[3] === "1" && (
                            <div className="col-2 text-center d-flex p-3">
                                <Button variant="primary" className="w-100" onClick={() => { setdefaultValues({}); PopupDevice(); }}>Toestel Toevoegen</Button>
                            </div>
                        )}
                    </div>
                    <DeviceTable
                        devices={devices}
                        installations={Installations}
                        rights={rights}
                        toggleStates={toggleStates}
                        handleToggle={handleToggle}
                        setdefaultValues={setdefaultValues}
                        PopupDelete={PopupDelete}
                        PopupDevice={PopupDevice}
                    />
                </div>
            </div>
            <DeletePopup
                Message={deletedeviceName}
                Url='/api/delete/Devices'
                Data={Deletedata}
                ref={deletePopupRef}
            />
            <DevicePopup
                Installations = {Installations}
                Clients={Clients}
                deviceTypes={deviceTypes}
                DefaultValues={MydefaultValues}
                ref={devicePopupRef}
            />
        </div>
    );
};