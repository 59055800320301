export function GetBinary(input) {
    if (typeof input === 'string') {
        input = parseInt(input, 10);
    }
    if (typeof input !== 'number' || isNaN(input)) {
        throw new Error("Input must be a valid number or a numeric string.");
    }
    let binaryStr = input.toString(2);
    binaryStr = binaryStr.padStart(8, '0');
    return binaryStr.split('').reverse().join('');
}
