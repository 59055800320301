import React, {useState} from "react";

const FileUpload = ({ Label, Name, Value, OnChange}) => {
    const [Checksumval, setChecksumval] = useState("");
    const handleFileChange = (event) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            setChecksumval(btoa(e.target.result))
            OnChange({...Value, [Name]: btoa(e.target.result)})
        };
        reader.readAsText(event.target.files[0]);
    };
    return (
        <div>
            {Label}: <input type="file" onChange={handleFileChange}/>
            <input type="hidden" name={Name} value={Checksumval} />
            <br/>
        </div>
    );
};

export {FileUpload};