import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { Button } from 'react-bootstrap';
const DeviceTable = ({devices, installations, rights, toggleStates, handleToggle, setdefaultValues, PopupDelete, PopupDevice}) => {
    function EditDevice(Unique_ID) {
        const CurDevice = devices.filter(device => device.Unique_ID === Unique_ID);
        setdefaultValues(CurDevice[0])
        PopupDevice()
    }
  return (
    <table className="table">
        <tr>
            <th>TOESTEL</th>
            <th>INSTALLATIE</th>
            <th>ID</th>
            <th>TYPE</th>
            <th>STATUS</th>
            <th>ACTIVEER</th>
            <th>AANPASSEN</th>
            {rights[2] === "1" && (<th>VERWIJDER</th>)}
        </tr>
        {devices.map((device, index) => {
            return (
                <tr>
                    <td>{device.Name}</td>
                    <td>{installations.filter(installation => installation.ID === device.Installation_ID)[0].Name}</td>
                    <td>{device.Unique_ID}</td>
                    <td>{device.Type}</td>
                    <td className={
                        device.Online === 1
                            ? "text-success"
                            : device.Online === 2
                                ? "text-warning"
                                : "text-danger"
                    }>
                        {device.Online === 1
                            ? "Online"
                            : device.Online === 2
                                ? "Compromised"
                                : "Offline"
                        }
                    </td>
                    <td>
                        <BootstrapSwitchButton
                            checked={toggleStates[device.Unique_ID]}
                            onlabel='DEACTIVEER'
                            offlabel='ACTIVEER'
                            size="sm"
                            width={100}
                            onChange={(checked) => handleToggle(device, checked)}
                        />
                    </td>
                    <td><Button variant="warning" onClick={() => EditDevice(device.Unique_ID)}>Aanpassen</Button></td>
                    {rights[2] === "1" && (
                        <td><Button variant="danger" onClick={() => PopupDelete(device.Unique_ID)}>Verwijder</Button>
                        </td>)}
                </tr>
            );
        })}
    </table>
  );
};
export {DeviceTable};