import React, { useState, useEffect } from 'react';
import {DeviceCard} from '../../CustomComponents'
import axios from '../../CustomComponents/Functions/AxiosInstance';
import {Button, Container, Row} from 'react-bootstrap';
const UserPage = () => {
    const [devices, setDevices] = useState([]);
  useEffect(() => {
    fetchDevices();
    const interval = setInterval(() => {
      fetchDevices();
    }, 5000);
    return () => clearInterval(interval); 
  }, []);
  const fetchDevices = async () => {
    try {
      const response = await axios.get('/api/GetClientStatus');
        const order = [0, 2, 1];
        setDevices(
            response.data.sort((a, b) => {
                // Compare based on the custom order defined in the 'order' array
                return order.indexOf(a.Online) - order.indexOf(b.Online);
            })
        );
    } catch (error) {
      console.error("Could not fetch devices", error);
    }
  };
  const handleLogout = () => {
    axios.post('/api/Logout')
        .then(response => {
          window.location.href = '/login';
        })
        .catch(error => {
          window.location.href = '/login';
        });
  };
  const currentDate = new Date();

  return (
      <div className='White'>
        <div className="d-flex justify-content-between align-items-center">
            <div>
                <span>{currentDate.toLocaleDateString('nl-NL', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                })}</span>
            </div>
            <div>
                <Button type="button" className="btn btn-dark" href={"/Tailscale"}>TAILSCALE</Button>
                <Button type="button" className="btn btn-dark" onClick={handleLogout}>LOGOUT</Button>
            </div>
        </div>
        <Container>
          <Row className="pt-3">
            {devices.map((device, index) => {
              return (
                  <DeviceCard Name={device.Name} LastConnection={device.Timestamp} Online={device.Online}
                              Status={device.Status} Link={"../" + device.Name}/>
              );
            })}
          </Row>
        </Container>
      </div>
  );
};

export {UserPage};