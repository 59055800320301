import React, { useRef, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import axios from '../../CustomComponents/Functions/AxiosInstance';
import { Button } from 'react-bootstrap';
import {GetBinary} from '../../CustomComponents'
import {AttributesPopup, DeletePopup} from './Components';
export const PluginForm = () => {
    const [Plugins, setPlugins] = useState([]);
    const [DeleteName, setDeleteName] = useState([]);
    const [DeleteData, setDeleteData] = useState([]);
    const [rights, setRights] = useState([]);
    const deletePopupRef = useRef();
    const devicePopupRef = useRef();
    const PopupAttributes = {
        Title: "Upload plugin",
        Fields: [
            {
                Type: "TextField",
                Label: "Version",
                Name: "Version"
            },
            {
                Type: "FileUpload",
                Label: "File",
                Name: "Checksum"
            }
        ],
        AddUID: false
    }
    useEffect(() => {
        fetchPlugins();
        const interval = setInterval(() => {
            fetchPlugins();
        }, 5000);
        return () => clearInterval(interval); 
    }, []);
    const handleDownload = (ID, Base64) => {
        let PluginName = ""
        let MyCode = ""
        let Extension = ""
        Plugins.map((plugin) => {
            if(plugin.ID === ID) {
                PluginName = plugin.Version
                if(Base64) {
                    MyCode = plugin.Checksum
                    Extension = ".txt"
                }
                else {
                    MyCode = atob(plugin.Checksum)
                    Extension = ".lua"
                }
            }
            return plugin;
        })
        const myVariable = MyCode;
        const blob = new Blob([myVariable], { type: 'text/plain' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = PluginName+Extension;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    const PopupDelete = (Id, Version) => {
        setDeleteName("Ben je zeker dat je plugin "+Version+" wilt verwijderen ?");
        setDeleteData({ID: Id});
        deletePopupRef.current.Popup();
    }
    const fetchPlugins = async () => {
        try {
            const response = await axios.get('/api/get/Plugin');
            setPlugins(response.data);
            const response2 = await axios.get('/api/GetRights');
            setRights(GetBinary(response2.data));
        } catch (error) {
            console.error("Could not fetch devices", error);
        }
    };
    if(rights[5]==="0") {
        window.location.href = '/';
    }
    return (
        <div className="container">
            <div className="row align-items-center justify-content-center">
                <div className="col-10 pt-5">
                    <Button variant="primary" className='' onClick={() => devicePopupRef.current.Popup()}>Add Plugin</Button>
                    <table className="table">
                        <tr>
                            <th>PLUGIN VERSION</th>
                            <th>DOWNLOAD BASE64</th>
                            <th>DOWNLOAD FILE</th>
                            <th>DELETE</th>
                        </tr>
                        {Plugins.map((plugin) => {
                            return (
                                <tr>
                                    <td>{plugin.Version}</td>
                                    <td><Button variant="primary"
                                                onClick={() => handleDownload(plugin.ID, true)}>Download</Button></td>
                                    <td><Button variant="primary"
                                                onClick={() => handleDownload(plugin.ID, false)}>Download</Button></td>
                                    <td><Button variant="danger"
                                                onClick={() => PopupDelete(plugin.ID, plugin.Version)}>Delete</Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </table>
                </div>
            </div>
            <DeletePopup
                Message={DeleteName}
                Url='/api/delete/Plugin'
                Data={DeleteData}
                ref={deletePopupRef}
            />
            <AttributesPopup DefaultValues="" Route="Plugin" ref={devicePopupRef} Attributes={PopupAttributes}/>
        </div>
    );
};