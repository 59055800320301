import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import axios from '../../../CustomComponents/Functions/AxiosInstance';
import {Selection, TextField} from '../../../CustomComponents'
const { v4: uuidv4 } = require('uuid');
const DevicePopup = forwardRef(({Installations, Clients, DefaultValues, deviceTypes}, ref) => {
    const [showAdd, setShowAdd] = useState(false);
    const [FormValues, setFormValues] = useState({
        Name: '',
        IP_Address: '',
        MAC: '',
        Manufacturer: '',
        Model: '',
        Firmware: '',
        Type: 'OTHER',
        API_user: '',
        API_pass: ''
    });
    const [AddInstallationSelected, setAddInstallationSelected] = useState("");
    const [AddClientSelected, setAddClientSelected] = useState("");
    const [AddTypeSelected, setTypeSelected] = useState("OTHER");
    const [AddInstallationList, setAddInstallationList] = useState([]);
    const handleCloseAdd = () => setShowAdd(false);
    function Popup() {
        setShowAdd(true)
    }
    useImperativeHandle(ref, () => ({
        Popup
    }));
    const UpdateAddInstallation = (event) => {
        setAddInstallationSelected(event.target.value)
    }
    const UpdateAddType = (event) => {
        setTypeSelected(event.target.value);
    }
    const UpdateAddClient = (event) => {
        let MyList = []
        Installations.map((installation, index) => {
            if(installation.Client_ID.toString() === event.target.value) {
                MyList.push(installation)
            }
            return installation;
        })
        setAddClientSelected(event.target.value)
        setAddInstallationSelected("")
        setAddInstallationList(MyList)
    }
    useEffect(() => {
        if (DefaultValues.Installation_ID) {
            const CurInstallation = Installations.filter(installation => installation.ID === DefaultValues.Installation_ID);
            let MyList = []
            Installations.map((installation, index) => {
                if(installation.Client_ID === CurInstallation[0].Client_ID) {
                    MyList.push(installation)
                }
                return installation;
            })
            setFormValues(DefaultValues)
            setAddInstallationList(MyList)
            setAddInstallationSelected(DefaultValues.Installation_ID);
            setAddClientSelected(CurInstallation[0].Client_ID);
            setTypeSelected(DefaultValues.Type);
        }
        else {
            setFormValues({
                Name: '',
                IP_Address: '',
                MAC: '',
                Manufacturer: '',
                Model: '',
                Firmware: '',
                Type: 'OTHER',
                API_user: '',
                API_pass: ''
            });
        }
    }, [DefaultValues, Installations]);
    const handleAdd = (event) => {
        if(AddInstallationSelected === "") {
            alert('Gelieve een installatie te selecteren.');
            return
        }
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = {};
        formData.forEach((value, key) => {
            data[key] = value;
        });
        data.Installation_ID = AddInstallationSelected;
        data.Type = AddTypeSelected;
        let url = ""
        if(DefaultValues.Installation_ID) {
            url = '/api/change/Devices'
        }
        else {
            url = '/api/add/Devices'
            data.Unique_ID = uuidv4();
        }
        axios.post(url, data)
          .then(response => {
            setShowAdd(false);
          })
          .catch(error => {
            console.error('Error adding device:', error.response ? error.response.data : 'Unknown error');
            alert('Failed to add device.');
          });
    }
  return (
    <Modal show={showAdd} onHide={handleCloseAdd}>
        <Modal.Header closeButton>
        <Modal.Title>Toestel Toevoegen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form id="loginForm" onSubmit={handleAdd}>
                <div>
                    <Selection
                        Label="Klant"
                        OnChange={UpdateAddClient}
                        Values={Clients}
                        ValueID="ID"
                        TextID="Name"
                        CurVal={AddClientSelected}
                    />
                </div>
                <div>
                    <Selection
                        Label="Installatie"
                        OnChange={UpdateAddInstallation}
                        Values={AddInstallationList}
                        ValueID="ID"
                        TextID="Name"
                        CurVal={AddInstallationSelected}
                    />
                </div>
                <TextField Label="Naam" Name="Name" Value={FormValues} OnChange={setFormValues}/>
                <TextField Label="IP" Name="IP_Address" Value={FormValues} OnChange={setFormValues}/>
                <TextField Label="MAC Address" Name="MAC" Value={FormValues} OnChange={setFormValues}/>
                <TextField Label="Fabrikant" Name="Manufacturer" Value={FormValues} OnChange={setFormValues}/>
                <TextField Label="Model" Name="Model" Value={FormValues} OnChange={setFormValues}/>
                <TextField Label="Firmware" Name="Firmware" Value={FormValues} OnChange={setFormValues}/>
                <TextField Label="API User" Name="API_user" Value={FormValues} OnChange={setFormValues} Required={false}/>
                <TextField Label="API pass" Name="API_pass" Value={FormValues} OnChange={setFormValues} Required={false}/>
                <div>
                    <Selection
                        Label="Type"
                        OnChange={UpdateAddType}
                        Values={deviceTypes}
                        ValueID="Type"
                        TextID="Type"
                        CurVal={AddTypeSelected}
                        HideAll="true"
                    />
                </div>
                <input type="hidden" name="ID" value={DefaultValues.ID}/>
                <Button variant="secondary" onClick={handleCloseAdd}>
                    Annuleer
                </Button>
                <Button variant="danger" type="submit">
                    Ok
                </Button>
            </form>
        </Modal.Body>
    </Modal>
  );
});
export {DevicePopup};