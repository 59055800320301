const Selection = ({ Label, OnChange, Values, ValueID, TextID, CurVal, SpecialClass, HideAll }) => {
  return (
    <div>
        <p className={SpecialClass}>{Label}:</p>
            <select class="form-select form-select-lg mb-3 col-5" aria-label=".form-select-lg example" onChange={OnChange} value={CurVal}>
                {HideAll == null && (<option value="">ALL</option>)}
                {Values.map((client, index) => {
                    return (<option value={client[ValueID]}>{client[TextID]}</option>)
                })}
            </select>
        </div>
  );
};

export {Selection};