import {Button, Container, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {CustomTable} from "../Admin";
import axios from '../../CustomComponents/Functions/AxiosInstance';

const Tailscale = () => {
    const [Values, setValues] = useState([]);
    function Connect(name, ip) {
        const texts = ["full address:s:"+ip]
        const file = new Blob(texts, {type: 'text/plain'});
        const element = document.createElement("a");
        element.href = URL.createObjectURL(file);
        element.download = name+".rdp";
        document.body.appendChild(element);
        element.click()
    }
    useEffect(() => {
        const fetchTailscale = async () => {
            const NewValues = []
            try {
                const response = await axios.post('/api/GetTailscale');
                for (const device of response.data.devices) {
                    if(device.tags && device.tags.includes("tag:proav-clients")) {
                        NewValues.push({
                            Client: device.hostname,
                            Ip: device.addresses[0],
                            Timestamp: new Date(device.lastSeen),
                            Connect: <Button variant="primary" onClick={() => Connect(device.hostname, device.addresses[0])}>Connect</Button>
                        })
                    }
                }
                setValues(NewValues)
            } catch (error) {
                console.error("Could not fetch tailscale", error);
            }
        }
        fetchTailscale()
    }, []);
    const COLUMNS = [
        {
            label: "Client",
            SortType: "text"
        },
        {
            label: "Ip",
            SortType: "text"
        },
        {
            label: "Timestamp",
            SortType: "Date"
        },
        {
            label: "Connect",
            SortType: "text"
        }
    ];
    return (
        <div className='White'>
            <Container>
                <Row className="pt-3">
                    <div className="text-center">
                        <a href={`../../`} className="link">
                            <h3 className="White link">Home/</h3>
                        </a>
                        <h3 className="White link">Tailscale</h3>
                        <CustomTable Columns={COLUMNS} Values={Values}/>
                    </div>

                </Row>
            </Container>
        </div>
    )
}
export {Tailscale};