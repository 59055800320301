import React, { useState, useEffect } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import axios from '../../CustomComponents/Functions/AxiosInstance';
import {GetBinary} from '../../CustomComponents'
import {NavItem} from './Components'
export const AdminMenu = () => {
    const [rights, setRights] = useState([]);
    useEffect(() => {
        fetchDevices();
        const interval = setInterval(() => {
            fetchDevices();
        }, 5000);
        return () => clearInterval(interval); 
    });
    const fetchDevices = async () => {
        try {
            const response = await axios.get('/api/GetRights');
            setRights(GetBinary(response.data));
        } catch (error) {
            console.error("Could not fetch devices", error);
        }
    };
    const handleLogout = () => {
        axios.post('/api/Logout')
          .then(response => {
            window.location.href = '/login';
          })
          .catch(error => {
            window.location.href = '/login';
          });
    };
    const inactiveStyle = {
        color: '#999999',  // You can customize the color or any other styles for active links
        textDecoration: 'none'
    };
    return (
        <div className="BackgroundColor">
            <nav className="navbar navbar-expand-lg navbar-light bg-dark">
                <div className="container-fluid">
                    <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <div className="navbar-nav">
                            <NavItem rights={rights[4]} link="/Admin/Clients" text="Klanten"/>
                            <NavItem rights={rights[4]} link="/Admin/Installations" text="Installaties"/>
                            <NavItem rights={rights[1]} link="/Admin/Devices" text="Toestellen"/>
                            <NavItem rights={rights[0]} link="/Admin/Accounts" text="Accounts"/>
                            <NavItem rights={rights[5]} link="/Admin/Plugins" text="Plugins"/>
                        </div>
                        <div className="navbar-nav ms-auto">
                            <NavLink  onClick={() => handleLogout()} className="nav-item nav-link" style={inactiveStyle}>
                                Logout
                            </NavLink>
                        </div>
                    </div>
                </div>
            </nav>
            <Outlet />
        </div>
    );
};