import React, { useRef, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import axios from '../../CustomComponents/Functions/AxiosInstance';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import {GetBinary, Pencil} from '../../CustomComponents'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import {DeletePopup} from './Components'
import bcrypt from 'bcryptjs';
const saltRounds = 10;
export const AccountsForm = (props) => {
    const [Deletedata, setDeleteData] = useState([]);
    const [DeleteDeviceName, setDeleteDeviceName] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [password, setPassword] = useState([""]);
    const [username, setUsername] = useState([""]);
    const [SelectedAccount, setSelectedAccount] = useState([1]);
    const [rights, setRights] = useState([]);
    const [showEdit, setShowEdit] = useState(false);
    const [showAddUser, setShowAddUser] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [toggleRights, settoggleRights] = useState({});
    const handleCloseAddUser = () => setShowAddUser(false);
    const handleCloseEdit = () => setShowEdit(false);
    const handleClosePassword = () => setShowPassword(false);
    const deletePopupRef = useRef();
    useEffect(() => {
        fetchDevices();
        const interval = setInterval(() => {
            fetchDevices();
        }, 5000);
        return () => clearInterval(interval); 
    }, []);
    const PopupDelete = (Id) => {
        const device = accounts.find(device => device.ID === Id);
        setDeleteDeviceName("Ben je zeker dat je "+device.Username+" wilt verwijderen ?");
        setDeleteData({ID: Id});
        deletePopupRef.current.Popup();
    }
    function handleAddUser() {
        if(password==="") {
            return
        }
        const url = '/api/add/Users';
        let newRights = 0
        const rightsValues = [1, 2, 4, 8, 16, 32];
        rightsValues.forEach((value, index) => {
            if (toggleRights[0]?.[index] === true) {
                newRights += value;
            }
        });
        const mypassword = bcrypt.hashSync(password, saltRounds);
        const data = {
          Rights: newRights,
          Password: mypassword,
          Username: username
        };
        axios.post(url, data)
          .then(response => {
          })
          .catch(error => {
            console.error('Error updating user:', error.response ? error.response.data : 'Unknown error');
            alert('Failed to add user.');
          });
          setShowAddUser(false)
      }
    function handleEdit() {
        let mypassword = password.toString()
        if(password==="") {
            mypassword = accounts[SelectedAccount].Password
        }
        const url = '/api/change/Users';
        let newRights = 0
        const rightsValues = [1, 2, 4, 8, 16, 32];
        rightsValues.forEach((value, index) => {
            if (toggleRights[SelectedAccount]?.[index] === true) {
                newRights += value;
            }
        });
        const bcryptPattern = /^\$2[ayb]\$.{56}$/;
        if(!bcryptPattern.test(mypassword)) {
            mypassword = bcrypt.hashSync(mypassword, saltRounds);
        }
        const data = {
          Rights: newRights,
          Password: mypassword,
          ID: accounts[SelectedAccount]?.ID,
        };
        axios.post(url, data)
          .then(response => {
          })
          .catch(error => {
            console.error('Error updating user:', error.response ? error.response.data : 'Unknown error');
            alert('Failed to update user.');
          });
          setShowEdit(false)
      }
    const fetchDevices = async () => {
        try {
            const response = await axios.get('/api/get/Users');
            setAccounts(response.data);
            const response2 = await axios.get('/api/GetRights');
            setRights(GetBinary(response2.data));
        } catch (error) {
            console.error("Could not fetch devices", error);
        }
    };
    const ShowPassword = (Id) => {
        setSelectedAccount(Id)
        settoggleRights(prev => ({ ...prev, [Id]: [GetBinary(accounts[Id]?.Rights ?? '')[0]=== "1" ? true : false , GetBinary(accounts[Id]?.Rights ?? '')[1]=== "1" ? true : false, GetBinary(accounts[Id]?.Rights ?? '')[2]=== "1" ? true : false, GetBinary(accounts[Id]?.Rights ?? '')[3]=== "1" ? true : false, GetBinary(accounts[Id]?.Rights ?? '')[4]=== "1" ? true : false, GetBinary(accounts[Id]?.Rights ?? '')[5]=== "1" ? true : false]}));
        setPassword("")
        setShowPassword(true)
    }
    const ShowAddAccount = (Id) => {
        setPassword("")
        setUsername("")
        setSelectedAccount(0)
        settoggleRights(prev => ({ ...prev, 0: [false, false, false, false]}));
        setShowAddUser(true)
    }
    const PopupEdit = (Id) => {
        setSelectedAccount(Id)
        settoggleRights(prev => ({ ...prev, [Id]: [GetBinary(accounts[Id]?.Rights ?? '')[0]=== "1" ? true : false , GetBinary(accounts[Id]?.Rights ?? '')[1]=== "1" ? true : false, GetBinary(accounts[Id]?.Rights ?? '')[2]=== "1" ? true : false, GetBinary(accounts[Id]?.Rights ?? '')[3]=== "1" ? true : false, GetBinary(accounts[Id]?.Rights ?? '')[4]=== "1" ? true : false, GetBinary(accounts[Id]?.Rights ?? '')[5]=== "1" ? true : false]}));
        setShowEdit(true)
    }
    const handleChangePassword = (d) => {
        handleEdit()
        setShowPassword(false)
    };
    const handletoggleRights0 = (deviceId, checked) => {
        settoggleRights(prev => ({ ...prev, [deviceId]: [checked, toggleRights[deviceId][1], toggleRights[deviceId][2], toggleRights[deviceId][3], toggleRights[deviceId][4], toggleRights[deviceId][5]] }));
    };
    const handletoggleRights1 = (deviceId, checked) => {
        settoggleRights(prev => ({ ...prev, [deviceId]: [toggleRights[deviceId][0], checked, toggleRights[deviceId][2], toggleRights[deviceId][3], toggleRights[deviceId][4], toggleRights[deviceId][5]] }));
    };
    const handletoggleRights2 = (deviceId, checked) => {
        settoggleRights(prev => ({ ...prev, [deviceId]: [toggleRights[deviceId][0], toggleRights[deviceId][1], checked, toggleRights[deviceId][3], toggleRights[deviceId][4], toggleRights[deviceId][5]] }));
    };
    const handletoggleRights3 = (deviceId, checked) => {
        settoggleRights(prev => ({ ...prev, [deviceId]: [toggleRights[deviceId][0], toggleRights[deviceId][1], toggleRights[deviceId][2], checked, toggleRights[deviceId][4], toggleRights[deviceId][5]] }));
    };
    const handletoggleRights4 = (deviceId, checked) => {
        settoggleRights(prev => ({ ...prev, [deviceId]: [toggleRights[deviceId][0], toggleRights[deviceId][1], toggleRights[deviceId][2], toggleRights[deviceId][3], checked, toggleRights[deviceId][5]] }));
    };
    const handletoggleRights5 = (deviceId, checked) => {
        settoggleRights(prev => ({ ...prev, [deviceId]: [toggleRights[deviceId][0], toggleRights[deviceId][1], toggleRights[deviceId][2], toggleRights[deviceId][3], toggleRights[deviceId][4], checked] }));
    };
    if(rights[0]==="0") {
        window.location.href = '/';
    }
    return (
        <div className="container">
            <div className="row align-items-center justify-content-center">
                <div className="col-10 pt-5">
                    <Button variant="primary" className='' onClick={ShowAddAccount}>Add User</Button>
                    <table className="table">
                        <tr>
                            <th>USER</th>
                            <th>RIGHTS</th>
                            <th>ACTIONS</th>
                        </tr>
                        {accounts.map((device, index) => {
                            return (
                                <tr>
                                    <td>{device.Username}</td>
                                    <td>{device.Rights} <Button variant="primary" onClick={() => PopupEdit(index)}><Pencil /></Button></td>
                                    <td><Button variant="danger" onClick={() => PopupDelete(device.ID)}>Delete</Button> <Button variant="danger" onClick={() => ShowPassword(index)}>Change Password</Button></td>
                                </tr>
                            );
                        })}
                    </table>
                </div>
            </div>
            <Modal show={showPassword} onHide={handleClosePassword}>
                <Modal.Header closeButton>
                <Modal.Title>Nieuw Wachtwoord</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input type="password" class="form-control" id="" value={password} onChange={(e) => setPassword(e.target.value)}/>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClosePassword}>
                    Nee
                </Button>
                <Button variant="danger" onClick={handleChangePassword}>
                    Ja
                </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showEdit} onHide={handleCloseEdit} size="lg">
                <Modal.Header closeButton>
                <Modal.Title>Rechten aanpassen:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="pt-5">
                            <table className="table">
                                <tr>
                                    <th>Gebruikers aanmaken</th>
                                    <th>Toestellen aanpassen</th>
                                    <th>Toestellen verwijderen</th>
                                    <th>Toestellen aanmaken</th>
                                    <th>Lokaties / klanten aanmaken</th>
                                    <th>Plugins aanmaken</th>
                                </tr>
                                <tr>
                                    <td>
                                    <BootstrapSwitchButton
                                        checked={toggleRights[SelectedAccount]?.[0] ?? false}
                                        onlabel='ENABLE'
                                        offlabel='DISABLE'
                                        size="sm"
                                        width={100}
                                        onChange={(checked) => handletoggleRights0(SelectedAccount, checked)}
                                    />
                                    </td>
                                    <td>
                                    <BootstrapSwitchButton
                                        checked={toggleRights[SelectedAccount]?.[1] ?? false}
                                        onlabel='ENABLE'
                                        offlabel='DISABLE'
                                        size="sm"
                                        width={100}
                                        onChange={(checked) => handletoggleRights1(SelectedAccount, checked)}
                                    />
                                    </td>
                                    <td>
                                    <BootstrapSwitchButton
                                        checked={toggleRights[SelectedAccount]?.[2] ?? false}
                                        onlabel='ENABLE'
                                        offlabel='DISABLE'
                                        size="sm"
                                        width={100}
                                        onChange={(checked) => handletoggleRights2(SelectedAccount, checked)}
                                    />
                                    </td>
                                    <td>
                                    <BootstrapSwitchButton
                                        checked={toggleRights[SelectedAccount]?.[3] ?? false}
                                        onlabel='ENABLE'
                                        offlabel='DISABLE'
                                        size="sm"
                                        width={100}
                                        onChange={(checked) => handletoggleRights3(SelectedAccount, checked)}
                                    />
                                    </td>
                                    <td>
                                    <BootstrapSwitchButton
                                        checked={toggleRights[SelectedAccount]?.[4] ?? false}
                                        onlabel='ENABLE'
                                        offlabel='DISABLE'
                                        size="sm"
                                        width={100}
                                        onChange={(checked) => handletoggleRights4(SelectedAccount, checked)}
                                    />
                                    </td>
                                    <td>
                                    <BootstrapSwitchButton
                                        checked={toggleRights[SelectedAccount]?.[5] ?? false}
                                        onlabel='ENABLE'
                                        offlabel='DISABLE'
                                        size="sm"
                                        width={100}
                                        onChange={(checked) => handletoggleRights5(SelectedAccount, checked)}
                                    />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseEdit}>
                    Nee
                </Button>
                <Button variant="danger" onClick={handleEdit}>
                    Ja
                </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showAddUser} onHide={handleCloseAddUser} size="lg">
                <Modal.Header closeButton>
                <Modal.Title>Gebruiker toevoegen:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="pt-5">
                            Username: <input type="text" class="form-control" id="" value={username} onChange={(e) => setUsername(e.target.value)}/>
                            Password: <input type="password" class="form-control" id="" value={password} onChange={(e) => setPassword(e.target.value)}/>
                            <table className="tablelight">
                                <tr>
                                    <th>Gebruikers aanmaken</th>
                                    <th>Toestellen aanpassen</th>
                                    <th>Toestellen verwijderen</th>
                                    <th>Toestellen aanmaken</th>
                                    <th>Lokaties / klanten aanmaken</th>
                                    <th>Plugins aanmaken</th>
                                </tr>
                                <tr>
                                    <td>
                                    <BootstrapSwitchButton
                                        checked={toggleRights[0]?.[0] ?? false}
                                        onlabel='ENABLE'
                                        offlabel='DISABLE'
                                        size="sm"
                                        width={100}
                                        onChange={(checked) => handletoggleRights0(SelectedAccount, checked)}
                                    />
                                    </td>
                                    <td>
                                    <BootstrapSwitchButton
                                        checked={toggleRights[0]?.[1] ?? false}
                                        onlabel='ENABLE'
                                        offlabel='DISABLE'
                                        size="sm"
                                        width={100}
                                        onChange={(checked) => handletoggleRights1(SelectedAccount, checked)}
                                    />
                                    </td>
                                    <td>
                                    <BootstrapSwitchButton
                                        checked={toggleRights[0]?.[2] ?? false}
                                        onlabel='ENABLE'
                                        offlabel='DISABLE'
                                        size="sm"
                                        width={100}
                                        onChange={(checked) => handletoggleRights2(SelectedAccount, checked)}
                                    />
                                    </td>
                                    <td>
                                    <BootstrapSwitchButton
                                        checked={toggleRights[0]?.[3] ?? false}
                                        onlabel='ENABLE'
                                        offlabel='DISABLE'
                                        size="sm"
                                        width={100}
                                        onChange={(checked) => handletoggleRights3(SelectedAccount, checked)}
                                    />
                                    </td>
                                    <td>
                                    <BootstrapSwitchButton
                                        checked={toggleRights[0]?.[4] ?? false}
                                        onlabel='ENABLE'
                                        offlabel='DISABLE'
                                        size="sm"
                                        width={100}
                                        onChange={(checked) => handletoggleRights4(SelectedAccount, checked)}
                                    />
                                    </td>
                                    <td>
                                    <BootstrapSwitchButton
                                        checked={toggleRights[0]?.[5] ?? false}
                                        onlabel='ENABLE'
                                        offlabel='DISABLE'
                                        size="sm"
                                        width={100}
                                        onChange={(checked) => handletoggleRights5(SelectedAccount, checked)}
                                    />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseAddUser}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handleAddUser}>
                    Toevoegen
                </Button>
                </Modal.Footer>
            </Modal>
            <DeletePopup
                Message={DeleteDeviceName}
                Url='/api/delete/Users'
                Data={Deletedata}
                ref={deletePopupRef}
            />
        </div>
    );
};