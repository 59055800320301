import React, { useRef, useState, useEffect, useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {Selection} from '../../CustomComponents'
import { Button } from 'react-bootstrap';
import axios from '../../CustomComponents/Functions/AxiosInstance';

import { InstallationTable, DeletePopup, InstallationPopup } from './Components';
export const InstallationsForm = (props) => {
    const [MydefaultValues, setdefaultValues] = useState([]);
    const [Clients, setClients] = useState([]);
    const [ClientSelected, setClientSelected] = useState("");
    const [Installations, setInstallation] = useState([]);
    const [DeleteName, setDeleteName] = useState([]);
    const [DeleteData, setDeleteData] = useState([]);
    const [Plugins, setPlugins] = useState()
    const devicePopupRef = useRef();
    const deletePopupRef = useRef();
    const fetchInstallations = useCallback(async () => {
        let params = ""
        if(ClientSelected!=="") {
            params = '"Client": "'+ClientSelected+'"'
        }
        axios.post('/api/GetInstallations', '{'+params+'}')
            .then(response => {
                setInstallation(response.data);
            })
            .catch(error => {
                console.error('Error getting installation:', error.response ? error.response.data : 'Unknown error');
            });
    }, [ClientSelected]);
    const fetchClients = async () => {
        const response = await axios.get('/api/Get/Client');
        setClients(response.data)
    };
    const fetchPlugins = async () => {
        const response = await axios.get('/api/get/Plugin');
        setPlugins(response.data)
    };
    const PopupDevice = () => {
        devicePopupRef.current.Popup();
    };
    useEffect(() => {
        fetchClients();
        fetchInstallations();
        fetchPlugins()
    }, [ClientSelected, fetchInstallations]);
    const PopupDelete = (Id) => {
        const device = Installations.find(installation => installation.ID === Id);
        setDeleteName("Ben je zeker dat je "+device.Name+" wilt verwijderen ?");
        setDeleteData({ID: Id});
        deletePopupRef.current.Popup();
    }
    function UpdateClient(event) {
        setClientSelected(event.target.value)
    }
    return (
        <div className="container">
            <div className="row align-items-center justify-content-center">
                <div className="col-10 pt-5">
                    <div className="row">
                        <div className="col-9 text-center">
                            <Selection
                                Label="Klant"
                                OnChange={UpdateClient}
                                Values={Clients}
                                ValueID="ID"
                                TextID="Name"
                                CurVal={ClientSelected}
                                SpecialClass="White Large"
                            />
                        </div>
                        <div className="col-3 text-center d-flex p-3">
                            <Button variant="primary" className="w-100" onClick={() => {setdefaultValues({});PopupDevice();}}>Installatie Toevoegen</Button>
                        </div>
                    </div>
                    <InstallationTable
                        Installations={Installations}
                        Clients={Clients}
                        PopupDelete={PopupDelete}
                        PopupDevice={PopupDevice}
                        setdefaultValues={setdefaultValues}
                    />
                </div>
            </div>
            <DeletePopup
                Message={DeleteName}
                Url='/api/delete/Installation'
                Data={DeleteData}
                ref={deletePopupRef}
            />
            <InstallationPopup
                Clients={Clients}
                Plugins={Plugins}
                DefaultValues={MydefaultValues}
                ref={devicePopupRef}
            />
        </div>
    )
};