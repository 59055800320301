import  React, { useState, useEffect, useCallback }  from "react";
import 'bootstrap/dist/css/bootstrap.css';
import { useParams } from 'react-router-dom';
import axios from '../../CustomComponents/Functions/AxiosInstance';
import {DeviceCard} from '../../CustomComponents'
import {Container ,Row} from 'react-bootstrap';
export const Installation = (props) => {
    const [devices, setDevices] = useState([]);
    const { clientId, installationId } = useParams();
    const fetchDevices = useCallback(async () => {
        try {
            const data = {Client: clientId, Installation: installationId};
            const response = await axios.post('/api/GetDevicesStatus', data);
            const order = [0, 2, 1];
            setDevices(
                response.data.sort((a, b) => {
                    // Compare based on the custom order defined in the 'order' array
                    return order.indexOf(a.Online) - order.indexOf(b.Online);
                })
            );
        } catch (error) {
            console.error("Could not fetch devices", error);
        }
    }, [clientId, installationId]);
    useEffect(() => {
      fetchDevices();
      const interval = setInterval(() => {
        fetchDevices();
      }, 5000);
      return () => clearInterval(interval); 
    }, [fetchDevices]);
    const currentDate = new Date();
    return (
      <div className='White'>{currentDate.toLocaleDateString('nl-NL', {day: '2-digit',month: '2-digit',year: 'numeric',hour: '2-digit',minute: '2-digit',hour12: false})}
        <Container>
          <Row className="pt-3">
              <div className="text-center">
                  <a href={`../../`} className="link">
                      <h3 className="White link">Home/</h3>
                  </a>
                  <a href={`../${clientId}`} className="link">
                      <h3 className="White link">{clientId}</h3>
                  </a>
                  <h3 className="link"> / </h3>
                  <h3 className="link">{installationId}</h3>
              </div>
              {devices.map((device, index) => {
                  return (
                      <DeviceCard Name={device.Name} LastConnection={device.Timestamp} Online={device.Online} Link={"../"+clientId+"/"+installationId+"/"+device.ID} Status={device.Status}/>
              );
            })}
          </Row>
        </Container>
      </div>
    );
};