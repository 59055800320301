import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import axios from '../../../CustomComponents/Functions/AxiosInstance';
import {Selection, TextField} from '../../../CustomComponents'
const { v4: uuidv4 } = require('uuid');
const InstallationPopup = forwardRef(({Clients, Plugins, DefaultValues}, ref) => {
    const [showAdd, setShowAdd] = useState(false);
    const [FormValues, setFormValues] = useState({
        Name: '',
        Address: "",
        Client_ID: "",
        BonNR: "",
        Plugin_ID: ""
    });
    const handleCloseAdd = () => setShowAdd(false);
    function Popup() {
        setShowAdd(true)
    }
    useImperativeHandle(ref, () => ({
        Popup
    }));
    const UpdateAddClient = (event) => {
        setFormValues({
            ...FormValues,
            Client_ID: event.target.value
        });
    }
    const UpdateAddPlugin = (event) => {
        setFormValues({
            ...FormValues,
            Plugin_ID: event.target.value
        });
    }
    useEffect(() => {
        if (DefaultValues.Client_ID) {
            setFormValues(DefaultValues)
        }
        else {
            setFormValues({
                Name: "",
                Address: "",
                Client_ID: "",
                BonNR: "",
                Plugin_ID: ""
            });
        }
    }, [DefaultValues]);
    const handleAdd = (event) => {
        if(FormValues["Client_ID"] === "") {
            alert('Gelieve een klant te selecteren.');
            return
        }
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = {};
        formData.forEach((value, key) => {
            data[key] = value;
        });
        data.Plugin_ID = FormValues["Plugin_ID"]
        data.Client_ID = FormValues["Client_ID"];
        let url = ""
        if(DefaultValues.Client_ID ) {
            url = '/api/change/Installation'
        }
        else {
            url = '/api/add/Installation'
            data.Unique_ID = uuidv4();
        }
        axios.post(url, data)
          .then(response => {
            setShowAdd(false);
          })
          .catch(error => {
            console.error('Error adding installation:', error.response ? error.response.data : 'Unknown error');
            alert('Failed to add installation.');
          });
    }
  return (
    <Modal show={showAdd} onHide={handleCloseAdd}>
        <Modal.Header closeButton>
        <Modal.Title>Installatie Toevoegen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form id="loginForm" onSubmit={handleAdd}>
                <div>
                    <Selection
                        Label="Klant"
                        OnChange={UpdateAddClient}
                        Values={Clients}
                        ValueID="ID"
                        TextID="Name"
                        CurVal={FormValues["Client_ID"]}
                    />
                </div>
                <TextField Label="Naam" Name="Name" Value={FormValues} OnChange={setFormValues}/>
                <TextField Label="Address" Name="Address" Value={FormValues} OnChange={setFormValues}/>
                <TextField Label="Bon nummer" Name="BonNR" Value={FormValues} OnChange={setFormValues}/>
                <div>
                    <Selection
                        Label="Plugin"
                        OnChange={UpdateAddPlugin}
                        Values={Plugins}
                        ValueID="ID"
                        TextID="Version"
                        CurVal={FormValues["Plugin_ID"]}
                    />
                </div>
                <input type="hidden" name="ID" value={DefaultValues.ID}/>
                <Button variant="secondary" onClick={handleCloseAdd}>
                    Annuleer
                </Button>
                <Button variant="danger" type="submit">
                    Ok
                </Button>
            </form>
        </Modal.Body>
    </Modal>
  );
});
export {InstallationPopup};