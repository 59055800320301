import { Button } from 'react-bootstrap';
const ClientTable = ({Clients, PopupDelete, PopupDevice, setdefaultValues}) => {
    function EditDevice(Unique_ID) {
        const CurDevice = Clients.filter(device => device.ID === Unique_ID);
        setdefaultValues(CurDevice[0])
        PopupDevice()
    }
  return (
    <table className="table">
        <tr>
            <th>KLANT</th>
            <th>ID</th>
            <th>AANPASSEN</th>
            <th>VERWIJDER</th>
        </tr>
        {Clients.map((Installation, index) => {
            return (
                <tr>
                    <td>{Installation.Name}</td>
                    <td>{Installation.Unique_ID}</td>
                    <td><Button variant="warning" onClick={() => EditDevice(Installation.ID)}>Aanpassen</Button></td>
                    <td><Button variant="danger" onClick={() => PopupDelete(Installation.ID)}>Verwijder</Button></td>
                </tr>
            );
        })}
    </table>
  );
};
export {ClientTable};